<template>
    <div class="full-height-layout fill mx-4" v-if="isAllowedTo('updateRoles')">
        <mercur-card class="full-height-layout fill form">
            <h1 class="font-weight-light my-0">
                <span v-if="isEdit">Edit role {{ originalData.roleName }}</span>
                <span v-else>Add role</span>
            </h1>
            <div class="fill">
                <div class="row">
                    <div class="col-6">
                        <mercur-input v-model="$v.form.roleName.$model" required :disabled="loading" :class="{'form-invalid': $v.form.roleName.$error}">
                            Role name
                            <template #note>
                                <span class="form-error" v-if="!$v.form.roleName.required">Role name is required</span>
                            </template>
                        </mercur-input>
                    </div>
                    <div class="col-12">
                        <mercur-spinner v-if="isFetchingPermissions"></mercur-spinner>
                        <template v-else>
                            <h3 class="font-weight-light">Permissions</h3>
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Permission override</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="permission in permissions" :key="permission.permissionId">
                                    <td>{{ permission.permissionName }}</td>
                                    <td>
                                        <mercur-checkbox v-model="permission.permissionStatus" :id="`permission-${permission.permissionId}`">Is allowed</mercur-checkbox>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <mercur-button @click="submit" :disabled="$v.$invalid || loading" class="btn btn-yellow text-uppercase btn-raised">Submit</mercur-button>
            </div>
        </mercur-card>
    </div>

    <p class="permission-message" v-else>Not allowed to see this view</p>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import CONFIG from '@root/config'

export default {
    name: 'AddEditRole',
    mixins: [ validationMixin ],
    data () {
        return {
            loading: false,
            permissions: [],
            isFetchingPermissions: false,
            originalData: {},
            searchPhrase: '',
            form: {
                roleName: null,
                permissions: {},
            },
        }
    },
    validations: {
        form: {
            roleName: { required },
        },
    },
    computed: {
        isEdit () {
            return !!this.$route.params.roleId
        },
        selectedPermissions () {
            let arr = []
            this.permissions.forEach((item) => {
                if (item.permissionStatus) {
                    arr.push(item.permissionId)
                }
            })
            return arr
        },
    },

    methods: {
        submit () {
            if (!this.isAllowedTo('updateRoles')) {
                return
            }

            this.form.permissions.permissionIds = this.selectedPermissions
            let url = CONFIG.API.ROUTES.ACCESS_CONTROL.ROLES.ADD

            if (this.isEdit) {
                url = CONFIG.API.ROUTES.ACCESS_CONTROL.ROLES.UPDATE
            }

            this.loading = true
            this.$api.post(url, this.form).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Role data was saved',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'AccessControlRoles',
                    })
                }, 500)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: `Saving data failed. Please try again.`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        fetchRole () {
            if (!this.isEdit || !this.isAllowedTo('updateRoles')) {
                return
            }

            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.ROLES.GET.replace('{roleId}', this.$route.params.roleId)
            this.loading = true
            this.addJob(url)
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.originalData = data.data.role
                this.form = JSON.parse(JSON.stringify(this.originalData))
                this.permissions = data.data.permissions
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        fetchPermissions () {
            if (!this.isAllowedTo('updateRoles')) {
                return
            }

            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.PERMISSIONS.OVERVIEW
            this.isFetchingPermissions = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.permissions = data.data.items
            }).finally(() => {
                this.isFetchingPermissions = false
            })
        },
    },
    created () {
        if (!this.isEdit) {
            this.fetchPermissions()
        }
        this.fetchRole()
    },
}
</script>

<style lang="scss" scoped>
    /deep/ h3 {
        font-size: 24px;
    }

    .form {
        max-width: 900px;
        &__content {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
</style>
